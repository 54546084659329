import classNames from 'classnames';
import { Divider, Image } from 'semantic-ui-react';
import styles from './Box.module.scss';

const Arrow = '/images/Arrow.png';

export default function Box({
  color,
  content,
  divider = true,
  image,
  more,
  size,
  title,
  onClick = false,
}) {
  const className = classNames('Box', styles.Box, styles[color], styles[`span-${size}`], {
    clickable: !!onClick,
  });
  const style = {};
  if (image) {
    Object.assign(style, {
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
    });
  }

  const img = more ? <Image size="tiny" className="more" src={Arrow} /> : null;
  const boxProps = { className };
  if (onClick) {
    boxProps.onClick = onClick;
  }

  return (
    <div {...boxProps}>
      <div style={style} className={classNames({ [styles.withImage]: !!image })}>
        <h2>{title}</h2>
        {divider ? <Divider /> : null}
        <div>{content}</div>
        {img}
      </div>
    </div>
  );
}
