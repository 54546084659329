import {
  Container, Embed, Icon, List, Modal, Button,
} from 'semantic-ui-react';
import Link from 'next/link';
import { useState } from 'react';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import Layout from '../components/FluidLayout';
import Sponsors from '../components/Sponsors';
import Box from '../components/Box';
import styleFilter from '../lib/styleFilter';
import styles from './index.module.scss';
import HeaderImage from '../components/Header-optimized.png';
import SlideshowImg from '../public/images/Elig.png';

export default function Index({
  sponsors = [], events = [], announcements = [], feature = {},
}) {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const [latestAnnouncement = {}] = announcements;
  const boxes = [
    {
      title: feature.title,
      more: true,
      onClick: () => router.push(feature.link),
      content: (
        <div>
          <p>{styleFilter(feature.body)}</p>
        </div>
      ),
      color: 'orange',
      size: 2,
    },
    {
      title: (
        <span>
          <Link style={{ color: 'white' }} href="/calendar">
            <>
              <Icon name="calendar alternate outline" /> Upcoming Events
            </>
          </Link>
        </span>
      ),
      content: (
        <List divided relaxed>
          {events.length > 0 ? (
            events.map((event) => {
              const s = new Date(event.startdate.replace(' ', 'T'));
              const e = new Date(event.enddate.replace(' ', 'T'));
              return (
                <List.Item key={event.id}>
                  <List.Content>
                    <List.Header title={event.name} className="eventTitle">
                      <a href={`/calendar/${event.id}`} style={{ color: 'white' }}>
                        {styleFilter(event.name)}
                      </a>
                    </List.Header>
                    <List.Description>
                      <span className="dateRange">
                        {`${format(s, 'MMM do, yyyy')} - ${format(e, 'MMM do, yyyy')}`}
                      </span>
                    </List.Description>
                  </List.Content>
                </List.Item>
              );
            })
          ) : (
            <List.Item>
              <List.Content>
                <List.Header className="eventTitle">No Events Found</List.Header>
                <List.Description>
                  <p style={{ color: 'white' }}>Check back soon!</p>
                </List.Description>
              </List.Content>
            </List.Item>
          )}
        </List>
      ),
      color: 'aqua',
      size: 1,
    },
    {
      title: 'LANA Member Discounts',
      content: <p>Click here for the latest PROMO codes from our sponsors</p>,
      color: 'grey',
      more: true,
      size: 1,
      onClick: () => router.push('/ce-providers-promo'),
    },
    {
      divider: false,
      isVideo: true,
      image: SlideshowImg.src,
      content: (
        <h1 style={{ visibility: 'hidden', height: '120px', width: '100%' }}>
          LANA Eligibility requirements
        </h1>
      ),
      color: 'white',
      more: false,
      size: 1,
    },
    {
      title: 'Announcements',
      content: (
        <div
          className={styles.announcement}
          dangerouslySetInnerHTML={{ __html: styleFilter(latestAnnouncement.content) }}
        />
      ),
      onClick: () => router.push('/announcements'),
      color: 'orange',
      more: true,
      size: 1,
    },
    {
      title: 'Certification',
      content: 'Why become CLT-LANA Certified? Find out more about the benefits....',
      color: 'aqua',
      onClick: () => {
        router.push('/certification');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      more: true,
      size: 1,
    },
    {
      title: 'Online Learning Center',
      content:
        'LANA has created an extensive repository of up-to-date useful information on lymphedema for including, articles, videos, product information, and LANA research right at your fingertips....',
      color: 'grey',
      onClick: () => router.push('/learning-center'),
      more: true,
      size: 2,
    },
  ];

  return (
    <Layout className="Index">
      <Container
        id="splash"
        textAlign="center"
        fluid
        style={{
          backgroundImage: `url(${HeaderImage.src})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          transformStyle: 'preserve-3d',
        }}
      >
        <Button
          id="findSpecialist"
          icon="search"
          size="huge"
          color="orange"
          content="FIND A CLT-LANA CERTIFIED SPECIALIST"
          style={{ marginTop: '120px', padding: '20px', boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)' }}
          onClick={() => router.push('/therapists')}
        />
      </Container>

      <Sponsors sponsors={sponsors} isHomePage />

      <Container>
        <Modal open={open} size="large" dimmer="blurring" className="videoContainer">
          <Modal.Content>
            <Embed id="_Vy1oMDkvdQ" autoplay controls width="100%" source="youtube" />
          </Modal.Content>
          <Modal.Actions>
            <Button color="teal" onClick={() => setOpen(false)}>
              <Icon name="close" /> Close
            </Button>
          </Modal.Actions>
        </Modal>

        <div className="Boxes">
          {boxes.map(
            (
              {
                title, divider, isVideo = false, onClick, image, content, color, more, size = 1,
              },
              i,
            ) => (
              <Box
                divider={divider}
                onClick={() => (isVideo ? setOpen(true) : onClick())}
                key={title || `divider-${i}`}
                color={color}
                size={size}
                title={title}
                more={more}
                content={content}
                image={image}
              />
            ),
          )}
        </div>
      </Container>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const sponsors = await context.req.inlineQuery(
    'SELECT * FROM sponsors WHERE active = 1 AND sponsor_level = "PREMIER" ORDER BY name ASC;',
    300,
  );
  const events = await context.req.inlineQuery(
    'SELECT * FROM event WHERE startdate > NOW() ORDER BY startdate ASC LIMIT 2',
    1800,
  );
  const announcements = await context.req.inlineQuery(
    'SELECT * FROM announcement WHERE active = 1 ORDER BY `order` ASC',
    600,
  );
  const [feature] = await context.req.inlineQuery('SELECT * FROM feature LIMIT 1');
  return {
    props: {
      sponsors,
      events,
      announcements,
      feature,
    },
  };
}
